
const academy = [
    {
        name: "Finance Programs",
        image:"/images/blur-1853262_1280.jpg"
    },
    {
        name: "HR Programs",
        image:"/images/HR3.jpg"
    },
    // {
    //     name: "Project Management Academy",
    //     image:"/images/pexels-shvets-production-7203724.jpg"
    // },
      {
          name: " Project Management Programs", 
        image:"/images/project.jpg"
     },
    // {
    //     name: "Coaching and Mentoring",
    //     image:"/images/coach.jpg"
    // },

]


export default academy;
