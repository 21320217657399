const Supplychainacademy = [
    // {
    //     heading1: "Distribution Management Excellence",
    //     point1: "Master efficient warehouse, transportation, and safety protocols for optimized distribution.",
    // },
    // {
    //     heading2: "Inter-Market Supply Chain Operations",
    //     point2: "Proficiently plan and execute inter-market transfers to manage multi-location logistics effectively.",
    // },
    // {
    //     heading3: "Effective Communication in Supply Chain",
    //     point3: "Proactively engage stakeholders and enhance customer and supplier relationships for service excellence."
    // },
    // {
    //     heading4: "Supply Chain Risk Management",
    //     point4: "Identify and mitigate complex logistical challenges and supply chain risks efficiently."
    // },
    // {
    //     heading5: "Technology Integration in Supply Chain",
    //     point5: "Harness emerging technologies like blockchain and robotics to optimize supply chain operations."
    // },
    // {
    //     heading6: "Supply Chain Leadership and Strategy",
    //     point6: "Develop strategic insights and leadership skills to align supply chain with organizational goals."
    // },
    // {
    //     heading7: "Continuous Improvement and Lean Practices",
    //     point7: "Implement lean principles and continuous improvement methodologies for enhanced efficiency."
    // },
    // {
    //     heading8: "Supply Chain Sustainability and Ethics",
    //     point8: "Integrate sustainability practices and ethical sourcing into supply chain operations for responsible management."
    // }
]

export default Supplychainacademy;